<template>
  <section class="containerCheck" @click="checkedUpdateFilter">
    <input
      type="checkbox"
      v-model="valueIsSelected"
      :id="filter.description"
      class="containerCheck__input"
    >
    <span class="containerCheck__checkmark"></span>
    <span class="containerCheck__label">
      <span class="containerCheck__label--label" :title="filter.description">{{ filter.description }}</span>
      <span class="containerCheck__label--counter">{{ filter.total }}</span>
    </span>
  </section>
</template>

<script>
import { onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'filterCheckBox',
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  emits: ['updateIsSelected'],
  setup (props, { emit }) {
    const store = useStore()
    const valueIsSelected = ref(false)

    onBeforeMount(() => {
      for (let i = 0; i < store.state.filters.temporaryFilters.length; i++) {
        const element = store.state.filters.temporaryFilters[i]
        if (element.description === props.filter.description && element.category === props.filter.category) {
          valueIsSelected.value = true
          emit('updateIsSelected', props.filter, true)
          break
        }
      }
    })

    /**
     * @description perform action when checkbox value is changed
     */
    function checkedUpdateFilter () {
      valueIsSelected.value = !valueIsSelected.value
      emit('updateIsSelected', props.filter, valueIsSelected.value)
      store.commit({
        type: props.filter.isSelected ? 'UPDATE_TEMP_QUERY_FILTER' : 'REMOVE_QUERY_FILTER',
        item: props.filter
      })
      store.commit({
        type: 'UPDATE_TEMP_QUERY_FILTER'
      })
      store.dispatch({
        type: 'getFilters',
        keepCategory: true
      })
    }

    return {
      store,
      valueIsSelected,
      checkedUpdateFilter
    }
  }
}
</script>
