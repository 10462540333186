<template>
  <section class="containerCategories" v-if="!store.state.isAPILoading">
    <transition-staggered tag="ul" type="vertically" class="containerCategories__list">
      <li v-for="(category, index) in store.state.filters.filterCategories"
          :key="index"
          :data-index="index">
        <filterCategoryTab :ref="setItemTabRef" :category="category" :index="index" :isModalMobile="isModalMobile"></filterCategoryTab>
      </li>
    </transition-staggered>
  </section>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import filterCategoryTab from '@/components/filterCategoryTab/filterCategoryTab'

export default {
  name: 'filterCategories',
  props: {
    isModalMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    filterCategoryTab
  },
  setup (props) {
    const store = useStore()
    const filterCategoriesTabRef = ref([])

    /**
     * @description add a new ref to refs array
     * @param el ref identifier to be added
     */
    const setItemTabRef = computed(() => (el) => {
      if (el) filterCategoriesTabRef.value.push(el)
    })

    /**
     * @description Calculates the number of columns
     */
    function calculateNumberOfColumns () {
      const indexOpenedTab = store.state.filters.filterCategories.findIndex((category) => category.isSelected)
      if (indexOpenedTab !== -1) {
        filterCategoriesTabRef.value[indexOpenedTab].calculateNumberOfColumns()
      }
    }

    return {
      store,
      setItemTabRef,
      calculateNumberOfColumns
    }
  }
}
</script>
