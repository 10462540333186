<template>
  <section class="containerFilterModal" ref="containerFilterModal" @click.stop="closeModal()">
    <section class="containerFilterModal__modal" @click.stop.prevent="">
        <section class="containerFilterModal__modal--header">
          <span class="clearAllButton" v-if="store.state.filters.temporaryFilters.length" @click="clearAllFilters">Clear all</span>
          <span class="title">Filters</span>
          <span class="grey-icon-close" @click.stop="closeModal()"></span>
        </section>
        <template v-if="!store.state.isAPILoading">
          <section class="containerFilterModal__modal--mainContent">
            <filterPilList ref="filterPillList" @calculateNumberOfColumns="calculateNumberOfColumns"></filterPilList>
            <section class="mainContent">
              <filterCategories ref="filterCategoriesRef" :isModalMobile="isModalMobile"></filterCategories>
              <filterList ref="filterListRef" v-if="!isModalMobile"></filterList>
            </section>
          </section>

          <section class="containerFilterModal__modal--footer">
            <button class="discardButton" @click.stop="closeModal()">DISCARD</button>
            <button class="applyButton" @click.stop="applyFilters()">APPLY</button>
          </section>
        </template>
        <skeletonFilterModal v-else></skeletonFilterModal>
    </section>
  </section>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import filterPilList from '@/components/filterPillList/filterPillList'
import filterCategories from '@/components/filterCategories/filterCategories'
import filterList from '@/components/filterList/filterList'
import skeletonFilterModal from '@/components/skeletons/skeletonFilterModal/skeletonFilterModal'

export default {
  name: 'filterModal',
  components: {
    filterPilList,
    filterCategories,
    filterList,
    skeletonFilterModal
  },
  emits: ['closeModal'],
  setup (props, { emit }) {
    const store = useStore()
    const selectedIndex = ref(0)
    const isModalMobile = ref(window.innerWidth <= 804)
    const containerFilterModal = ref(null)
    const filterPillList = ref(null)
    const filterListRef = ref(null)
    const filterCategoriesRef = ref(null)

    onMounted(() => {
      containerFilterModal.value.style.setProperty('--mobileHeightFilterModal', `${window.innerHeight}px`)
      if (store.state.isMobile) {
        setTimeout(() => (document.getElementById('app').style.height = '0px'), 1000)
      }
      resizeSetUp()
      handleResize()
    })

    onUnmounted(() => {
      if (store.state.isMobile) {
        document.getElementById('app').removeAttribute('style')
      }

      store.commit({
        type: 'SELECT_CATEGORY_FILTER'
      })
      emit('closeModal', false)
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      isModalMobile.value = window.innerWidth <= 804
    }

    /**
     * @description Closes modal.
     */
    function closeModal () {
      store.commit({
        type: 'TOGGLE_VISIBLE_CONTENT',
        hideMainContent: false
      })
      store.commit({
        type: 'UPDATE_SELECTED_FILTERS',
        temporaryFilters: store.state.filters.selectedFilters
      })
      store.commit({
        type: 'UPDATE_TEMP_QUERY_FILTER'
      })
      store.commit({
        type: 'UPDATE_URL'
      })
    }

    /**
     * @description Applies selected filters to current search.
     */
    function applyFilters () {
      store.commit({
        type: 'UPDATE_FINAL_FILTERS',
        selectedFilters: store.state.filters.temporaryFilters
      })

      store.commit({
        type: 'CLEAN_PAGINATION'
      })

      store.commit({
        type: 'SORT_TYPE',
        parameter: 'search.score()',
        order: 'desc'
      })

      store.dispatch({
        type: 'applyFilters'
      })

      store.commit({
        type: 'REMOVE_QUERY_FILTER'
      })

      closeModal()
    }

    /**
     * @description Clears all the filters.
     */
    function clearAllFilters () {
      filterPillList.value && filterPillList.value.clearAllFilters()
      calculateNumberOfColumns()
    }

    /**
     * @description Calculates the number of columns
     */
    function calculateNumberOfColumns () {
      if (!isModalMobile.value) {
        filterListRef.value.calculateNumberOfColumns()
      } else {
        filterCategoriesRef.value.calculateNumberOfColumns()
      }
    }

    return {
      store,
      selectedIndex,
      isModalMobile,
      containerFilterModal,
      filterPillList,
      closeModal,
      applyFilters,
      clearAllFilters,
      filterListRef,
      filterCategoriesRef,
      calculateNumberOfColumns
    }
  }
}
</script>
