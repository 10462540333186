<template>
  <section class="containerFilterList" ref="containerFilterList">
    <h1 class="containerFilterList__title">{{selectedCategoryName}}</h1>
    <section class="containerFilterList__filterSearch" v-if="isSearchVisible()">
      <span class="containerFilterList__filterSearch--input">
        <span class="grey-icon-search"></span>
        <input class="input" type="text" v-model="searchTerm" ref="filterSearch" placeholder="Search..."/>
      </span>
    </section>

    <section class="containerFilterList__containerList" ref="containerFilterValues">
      <ul class="containerFilterList__containerList--list">
        <li v-for="(filter) in filteredFilters" :key="filter.description + store.state.filters.filterVersion + filter.category" class="filter">
          <filterCheckBox :filter="filter" @updateIsSelected="updateIsSelected" />
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { onMounted, computed, watch, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import filterCheckBox from '@/components/filterCheckBox/filterCheckBox'

export default {
  name: 'filterList',
  props: {
    isModalMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    filterCheckBox
  },
  setup (props) {
    const store = useStore()
    const searchTerm = ref('')
    const containerFilterList = ref(null)
    const filterSearch = ref(null)
    const containerFilterValues = ref(null)

    /**
     * @description Returns an array of filters filtered by user input, applying a 'like' search.
     */
    const filteredFilters = computed(() => {
      if (!searchTerm.value) {
        return store.getters.visibleFilters
      }
      return filterFilterList(searchTerm.value.toLowerCase().trimLeft().trimRight())
    })

    /**
     * @description Returns the name of the currently selected category.
     */
    const selectedCategoryName = computed(() => {
      return store.getters.getFilterCategoryName(store.state.filters.selectedCategory.description)
    })

    onMounted(() => {
      searchTerm.value = ''
      calculateNumberOfColumns()
    })

    /**
     * @description Check for changes of the currently selected category to reset list
     */
    watch(() => selectedCategoryName.value, () => {
      searchTerm.value = ''
      calculateNumberOfColumns()
      containerFilterValues.value.scrollTop = 0
    })

    /**
     * @description Recalculates the width of the columns if it is needed.
     */
    watch(() => searchTerm.value, () => {
      calculateNumberOfColumns()
    })

    /**
     * @description Filters list of filters using a given string and searching coincidences in
     * their description.
     * @param searchTermParam term to search for coincidences in th filters' description.
     */
    function filterFilterList (searchTermParam) {
      return store.getters.visibleFilters.filter((filter) => {
        return filter.description.toLowerCase().indexOf(searchTermParam) !== -1
      })
    }

    /**
     * @description Returns a flag indicating if filters search input should be painted.
     */
    function isSearchVisible () {
      return store.getters.visibleFilters.length > 10
    }

    /**
     * @description Sets the number of columns that component will have.
     */
    function calculateNumberOfColumns () {
      if (!containerFilterList.value) {
        return
      }
      const containerOfColumns = containerFilterList.value
      if (props.isModalMobile) {
        containerOfColumns.style.setProperty('--numberOfRows', filteredFilters.value.length)
        containerOfColumns.style.setProperty('--columnWidth', '90%')
      } else {
        const numberOfRows = Math.ceil((containerFilterList.value.offsetHeight - 136) / 30)
        nextTick(() => {
          if (filteredFilters.value.length > (numberOfRows * 2)) {
            containerOfColumns.style.setProperty('--numberOfRows', Math.ceil(filteredFilters.value.length / 2))
            containerOfColumns.style.setProperty('--columnWidth', '220px')
          } else if (filteredFilters.value.length > numberOfRows) {
            containerOfColumns.style.setProperty('--numberOfRows', numberOfRows)
            containerOfColumns.style.setProperty('--columnWidth', '220px')
          } else {
            containerOfColumns.style.setProperty('--numberOfRows', filteredFilters.value.length)
            containerOfColumns.style.setProperty('--columnWidth', 'auto')
          }
        })
      }
    }

    /**
     * @description Set isSelected filter property
     * @param newValue new value to set
     * @param index position of the filter
     */
    function updateIsSelected (filter, newValue) {
      filter.isSelected = newValue
      calculateNumberOfColumns()
    }

    return {
      store,
      searchTerm,
      containerFilterList,
      filterSearch,
      containerFilterValues,
      filteredFilters,
      selectedCategoryName,
      isSearchVisible,
      updateIsSelected,
      calculateNumberOfColumns
    }
  }
}
</script>
