<template>
  <genericModalLogic :title="'Save Search As...'"
                     class="containerSaveSearchModal"
                     v-if="isModalVisible"
                     @closeModal="close">
    <div class="containerSaveSearchModal__title">
      <p class="containerSaveSearchModal__title--label">SEARCH TITLE</p>
      <input type="text" class="containerSaveSearchModal__title--titleInput" maxlength="58" v-model="searchTitle">
    </div>
    <section class="containerSaveSearchModal__buttons">
      <button class="containerSaveSearchModal__buttons--cancel" @click.stop="close">CANCEL</button>
      <button class="containerSaveSearchModal__buttons--save" @click.stop="saveSearch" :disabled="!searchTitle || isSavingSearch">SAVE</button>
    </section>
  </genericModalLogic>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import genericModalLogic from '../genericModalLogic/genericModalLogic'

export default {
  name: 'saveSearchModal',
  components: {
    genericModalLogic
  },
  emits: ['showDeleteErrorToast'],
  setup (props, { emit }) {
    const store = useStore()
    const isModalVisible = ref(false)
    const searchTitle = ref('')
    const isSavingSearch = ref(false)

    /**
     * @description Opens the modal.
     */
    function open () {
      searchTitle.value = ''
      isSavingSearch.value = false
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     * @param event Dom event
     */
    function close (event) {
      event.preventDefault()
      isModalVisible.value = false
    }

    /**
     * @description Save current search
     */
    function saveSearch () {
      isSavingSearch.value = true
      store.dispatch({
        type: 'saveSearch',
        title: searchTitle.value
      }).then((response) => {
        if (response.success) {
          store.commit({
            type: 'SET_SEARCH_SAVED_INFORMATION',
            searchSaved: true,
            searchId: response.id
          })
          isModalVisible.value = false
        } else {
          emit('showDeleteErrorToast')
        }
      }).catch((error) => {
        console.error(error)
        emit('showDeleteErrorToast')
      })
    }

    return {
      store,
      isModalVisible,
      searchTitle,
      isSavingSearch,
      open,
      close,
      saveSearch
    }
  }
}
</script>
