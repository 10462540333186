<template>
  <section id="containerResults">
    <section class="containerResults" :class="[store.state.defaultFlow || 'search']">
      <section class="containerResults__title">
        <span class="containerResults__title--label" @click="goToHome">{{configuration.title}}</span>
        <a href="/uploadFile">
          <button class="containerResults__title--uploadButton" v-if="configuration.isUploadButtonVisible" @click.stop.prevent="openForm">
            <span class="grey-icon-upload uploadButton"></span>
            <span class="uploadLabel">UPLOAD</span>
          </button>
        </a>
      </section>
      <static-auto-complete v-if="configuration.showSearchBar" :searchTitle="configuration.searchTitle" :isSearchTermUpdated="isSearchTermUpdated"/>
      <div class="containerResults__pageTitle" v-else>
        <span class="containerResults__pageTitle">{{store.state.salesForceInformation.name}}</span>
      </div>
      <section>
        <filter-pill-list view='results' ref="filterPillListRef"></filter-pill-list>
        <section>
          <misspellLink @updateSearchInput="updateSearchInput"></misspellLink>
          <section class="containerResults__headerResults">
            <section class="containerResults__headerResults--totalResults">
              <div class="resultsCount">
                <span class="resultsCount__label" :class="{'hidden': !isContentLoaded}">{{store.state.totalResultsCount}} Result<span v-if="store.state.totalResultsCount > 1" class="resultsCount__label--labelFor">s</span> <span class="resultsCount__label--labelFor" v-if="keyWord">for “{{keyWord}}”</span></span>
                <div class="resultsCount__searchMade" v-if="showSaveSearchOption(false)" @click="openSaveSearchModal">
                  <span class="grey-icon-heart"></span>
                  <span class="resultsCount__searchMade--label">Save Search</span>
                </div>
                <div class="resultsCount__searchSaved" v-if="showSaveSearchOption(true)" @click="deleteSavedSearch">
                  <span class="grey-icon-heart-fill"></span>
                  <span class="resultsCount__searchSaved--label">Search Saved</span>
                </div>
                <div class="resultsCount__searchMade" v-if="showEditingSearchOption" @click="saveEditedSavedSearch">
                  <span class="grey-icon-heart"></span>
                  <span class="resultsCount__searchMade--label">Save Edited Search</span>
                </div>
              </div>
              <section class="menu" :class="{'hidden': !isContentLoaded}">
                <section class="menu__sourceMenu" v-if="configuration.showSourceSelector">
                  <div class="menu__sourceMenu--showResults">
                    <span class="label">Results <span class="label__from">from</span></span>
                  </div>
                  <filter-per-app></filter-per-app>
                </section>
                <selectAllCards
                  :class="{'containerSelectAllCards__searchSite': store.state.currentSite === 'SEARCH_SITE'}"
                  v-if="allowSelectAllCards"></selectAllCards>
                <section class="menu__filtersAndSort" :class="{'sourceVisible': configuration.showSourceSelector}">
                  <button class="menu__filtersAndSort--viewModeButton" @click="toggleView" v-if="configuration.showToggleView && !store.state.isTabletResolution && store.state.totalResultsCount">
                    <span :class="store.state.isGridView ? 'grey-icon-list-view': 'grey-icon-grid-view'"></span>
                  </button>
                  <button class="menu__filtersAndSort--filterButton" @click="openFilters" v-if="configuration.showFilters && displayFiltersButton">
                    <span class="label">Filters</span>
                    <span class="grey-icon-filters"></span>
                  </button>
                  <sort-buttons v-if="configuration.showSortButton" :key="`${store.state.sortType.parameter} ${store.state.sortType.order}`"/>
                </section>
              </section>
            </section>
          </section>
          <section class="cardsWrapper" v-if="isContentLoaded" ref="cardsWrapperRef" :style="minHeightCardsWrapper">
            <transition-staggered tag="ul" type="vertically" class="containerResults__cards" :class="showGridView ? 'gridView' : 'listView'">
              <li v-for="(resultItem, index) in resultItems" :key="index" :data-index="index" class="containerResults__cards--card">
                <cardResult
                  :resultItem="resultItem"
                  @clickOnPreview="togglePreviewVisibility(true, resultItem.indexItem)"
                  :tooltipMsg="configuration.tooltipMsg"
                  :showCheckBoxOnCard="configuration.showSelectChecks"
                  @openCollectionsModal="toogleCollectionsModal"
                  @openAddToPortfolioModal="openAddToPortfolioModal"
                  @openDeleteModal="openDeleteModal"
                  :index="index"
                  @openLikesModal="openLikesModal"
                  :isGridView="showGridView"
                  @openHighlightModal="toggleHighLightModal(true, $event)"
                >
                </cardResult>
              </li>
            </transition-staggered>
          </section>
          <paginator
            v-if="isContentLoaded"
            :totalItems="store.state.totalResultsCount"
            :maxPages="maxPages"
            :key="resultItems.length"
            :data-index="resultItems.length"
            :pagination="store.state.pagination"
            @goToPage="goToPage">
          </paginator>
          <no-results-message v-if="isContentLoaded && store.state.dataCards.value && !store.state.dataCards.value.length"></no-results-message>
        </section>
        <skeletonResults v-if="!isContentLoaded" :cardsCount="cardsCount" :isGridView="showGridView"></skeletonResults>
      </section>
    </section>
    <filterModal v-if="store.state.hideMainContent"></filterModal>
    <addRemoveItemsToCollectionGeneric
      v-if="store.state.collectionsInfo.isCollectionModalOpened"
      @goToNewCollection="forwardToCreateCollectionModal"
      @close="toogleCollectionsModal(null, false)"
      :cardsId="[store.state.collectionsInfo.cardId]"
      :collectionsList="store.state.collectionsMine"
      @updateCollectionContainId="updateCollectionContainId($event)">
    </addRemoveItemsToCollectionGeneric>
    <createCollectionModalGeneric
      v-if="createCollectionModalVisible"
      :creatingWithItems="true"
      :cardsId="[addCollectionModalID]"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="backToCollectionModal"
      @close="toggleCreateCollectionsModal(false)"
    ></createCollectionModalGeneric>
    <peopleListModalGeneric v-if="likesModalVisible" :itemId="likesModalID" @close="toggleLikesModal(false)"></peopleListModalGeneric>
    <addItemToPortfolio ref="portfolioModal" />
    <saveSearchModal ref="saveSearchModalRef" @showDeleteErrorToast="showDeleteErrorToast" />
    <deleteItem ref="deleteItemModal"
      :source="deleteItemSource"
      @showDeleteErrorToast="showDeleteErrorToast"
      @dispatchSearch="dispatchSearch"
    />
    <toast v-if="deleteErrorToast" :class="{'marginToolToast': !store.state.isMobile}"
      :message="errorMessageToast">
    </toast>
    <genericModalLogic theme="gray"
      class="previewModal"
      :class="additionalSourceClass"
      :title="previewItem.title"
      :tooltipTitle="previewItem.title"
      @closeModal="togglePreviewVisibility(false)"
      v-if="isPreviewVisible">
      <generalPreview
        :source="previewItem.source === 'gogreyNews' ? 'news' : previewItem.source"
        :previewItem="previewItem"
        :contentUrl="previewItemContentUrl"
      >
      </generalPreview>
    </genericModalLogic>
    <highLightModalGeneric
      v-if="highLightModalVisible"
      requestHighlight
      :highlightId="highLightObject.highlightId"
      application="Search"
      :isHighLightModalMobile="store.state.isHighLightModalMobile"
      @close="toggleHighLightModal(false)">
    </highLightModalGeneric>
  </section>
</template>

<script>
import { onMounted, computed, ref, reactive, nextTick, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import staticAutoComplete from '@/components/autoComplete/staticAutoComplete'
import cardResult from '@/components/cardResult/cardResult'
import paginator from '@/components/paginator/paginator'
import noResultsMessage from '@/components/noResultsMessage/noResultsMessage'
import filterModal from '@/components/filterModal/filterModal'
import filterPillList from '@/components/filterPillList/filterPillList'
import misspellLink from '@/components/misspellLink/misspellLink'
import filterPerApp from '@/components/filterPerApp/filterPerApp'
import sortButtons from '@/components/sortButtons/sortButtons'
import selectAllCards from '@/components/selectAllCards/selectAllCards'
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import addItemToPortfolio from '@/components/addItemToPortfolioModal/addItemToPortfolioModal'
import deleteItem from '@/components/deleteItemModal/deleteItemModal'
import saveSearchModal from '@/components/saveSearchModal/saveSearchModal'
import messages from '../../messages.json'
import { trackPagination } from '@/utils/azureAnalytics'
import generalPreview from '@/components/generalPreview/generalPreview'
import skeletonResults from '../skeletons/skeletonResults/skeletonResults'

export default {
  name: 'resultsPage',
  props: {
    config: {
      type: Object
    }
  },
  components: {
    staticAutoComplete,
    cardResult,
    paginator,
    noResultsMessage,
    filterModal,
    filterPillList,
    misspellLink,
    filterPerApp,
    sortButtons,
    selectAllCards,
    genericModalLogic,
    addItemToPortfolio,
    deleteItem,
    saveSearchModal,
    generalPreview,
    skeletonResults
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const maxPages = ref(6)
    const configuration = reactive(Object.assign({}, {
      title: 'GO.GREY',
      defaultSortParam: null,
      showSelectChecks: true,
      showSourceSelector: true,
      isUploadButtonVisible: false,
      searchTitle: 'Search...',
      tooltipMsg: 'Click for more info',
      homePage: '/',
      showFilters: true,
      showSaveSearch: true,
      showToggleView: true,
      showSortButton: true,
      showSearchBar: true
    }, props.config))
    const errorMessageToast = ref(messages.generic.errorMessageToast)
    const deleteErrorToast = ref(null)
    const likesModalVisible = ref(false)
    const createCollectionModalVisible = ref(false)
    const likesModalID = ref(null)
    const addCollectionModalID = ref(null)
    const isPreviewVisible = ref(false)
    const previewItem = ref(null)
    const additionalSourceClass = ref('')
    const previewItemContentUrl = ref('')
    const filterPillListRef = ref(null)
    const cardsWrapperRef = ref(null)
    const portfolioModal = ref(null)
    const saveSearchModalRef = ref(null)
    const deleteItemModal = ref(null)
    const isSearchTermUpdated = ref(false)
    const deleteItemSource = ref(null)
    const cardsCount = ref(16)
    const highLightModalVisible = ref(false)
    const highLightObject = ref({})
    const minHeightCardsWrapper = ref(0)

    /**
     * @description Parses response got from API to show result of search in cards.
     */
    const resultItems = computed(() => {
      return parseReponse(store.state.dataCards.value)
    })

    /**
     * @description Get keyword written by user
     */
    const keyWord = computed(() => {
      if (!store.state.exactSearch && store.state.spellSuggestion.newSpell && store.state.spellSuggestion.newSpellNumber) {
        return store.state.spellSuggestion.newSpell
      } else {
        return store.getters.searchTermFromUrl(route.query) || store.state.searchValue
      }
    })

    /**
     * @description Check if the filter button should be displayed or not
     */
    const displayFiltersButton = computed(() => {
      return store.state.source !== 'Office365' && store.state.totalResultsCount
    })

    /**
     * @description Check which save search option display
     * @param option flag indicating mode
     */
    const showSaveSearchOption = computed(() => {
      return option => store.state.showData
        ? (configuration.showSaveSearch &&
          (option ? !store.state.saveSearch.editingSearchHasChanged : !store.state.saveSearch.isEditingSearch) &&
          store.state.saveSearch.searchMade &&
          (option ? store.state.saveSearch.searchSaved : !store.state.saveSearch.searchSaved && (store.state.dataCards.value && store.state.dataCards.value.length)) &&
          !store.state.spellSuggestion.newSpell)
        : false
    })

    /**
     * @description Check if save edited search option is desplayed
     */
    const showEditingSearchOption = computed(() => {
      return store.state.showData && store.state.saveSearch.isEditingSearch && store.state.saveSearch.searchMade && store.state.dataCards.value.length && store.state.saveSearch.editingSearchHasChanged
    })

    /**
     * @description Check if grid view should be displayed
     */
    const showGridView = computed(() => {
      return store.state.isGridView && !store.state.isTabletResolution
    })

    /**
     * @description Check if the filter button should be displayed or not
     */
    const allowSelectAllCards = computed(() => {
      const isFilterBySourceInSearch = store.state.source !== 'All sites' && store.state.currentSite === 'SEARCH_SITE'
      return (store.state.searchValue.length || store.state.filters.selectedFilters.length || isFilterBySourceInSearch) && configuration.showSelectChecks && (store.state.totalResultsCount || !isContentLoaded.value)
    })

    /**
     * @description Check content is loaded
     */
    const isContentLoaded = computed(() => {
      return store.state.showData
    })

    onBeforeMount(() => {
      setUpResultsPage()
    })

    onMounted(() => {
      window.onpopstate = function (event) {
        if (window.location.href !== `https://${window.location.host}/`) {
          location.reload()
        }
      }
      resizeSetUp()
      handleResize()
    })

    /**
     * @description Closes the Preview when there is a change in the cards.
     */
    watch(() => store.state.showData, () => {
      togglePreviewVisibility(false)
    })

    /**
     * @description Watch for isContentLoaded computed prop changes
     * @param isContentLoadedValue New value for isContentLoadedValue
     */
    watch(() => isContentLoaded.value, (isContentLoadedValue) => {
      if (isContentLoadedValue) {
        restoreScrollPosition()
        nextTick(() => {
          if (store.state.dataCards.value && store.state.dataCards.value.length) {
            const topOfCardsWrapper = cardsWrapperRef.value.getBoundingClientRect().top
            minHeightCardsWrapper.value = `--minHeight: ${window.innerHeight - topOfCardsWrapper - 213}px`
          } else {
            minHeightCardsWrapper.value = '--minHeight: 0'
          }
        })
      }
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      if ((window.innerWidth <= 576) && (window.innerWidth > 0)) {
        maxPages.value = 3
      } else {
        maxPages.value = 6
      }
    }

    /**
     * @description Set up all params and logic when into the page
     */
    function setUpResultsPage () {
      store.commit({
        type: 'SET_EXACT_SEARCH',
        exactSearch: route.query.q[0] === '"' && route.query.q[route.query.q.length - 1] === '"'
      })

      store.commit({
        type: 'SET_PAGINATION',
        size: cardsCount.value,
        page: route.query.k
      })
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: store.getters.searchTermFromUrl(route.query) || store.state.searchValue,
        apply: true
      })

      const defaultSources = { SEARCH_SITE: 'all sites', INTELL_SITE: 'intelligence', WORK_SITE: 'work' }
      const defaultFlowParam = store.state.defaultFlow === 'salesForce' ? route.query.s : store.state.defaultFlow
      store.commit({
        type: 'SET_SOURCE_TO_SEARCH',
        source: defaultFlowParam || route.query.s || defaultSources[store.state.currentSite]
      })
      if (route.query.o) {
        const orderParams = route.query.o
        const orderParameter = orderParams.split(' ')[0]
        store.commit({
          type: 'SORT_TYPE',
          parameter: orderParameter === 'Relevance' ? 'search.score()' : orderParameter,
          order: orderParams.split(' ')[1]
        })
      } else {
        store.commit({
          type: 'SORT_TYPE',
          parameter: configuration.defaultSortParam || 'search.score()',
          order: 'desc'
        })
      }

      if (route.query.f) {
        applyFiltersFromUrl(route.query.f)
      } else {
        searchWithoutFilters()
      }

      resizeSetUp()
    }

    /**
     * @description Set up scroll position on back from detail page
     */
    function restoreScrollPosition () {
      const appElement = document.querySelector('#app #app')
      const scrollTopPosition = localStorage.getItem('scrollTop')
      const scrollTopFlag = localStorage.getItem('restoreScrollTop')
      nextTick(() => {
        if (scrollTopPosition && scrollTopFlag) appElement.scrollTop = scrollTopPosition
        localStorage.removeItem('scrollTop')
        localStorage.removeItem('restoreScrollTop')
      })
    }

    /**
     * @description Execute the action required when a new page is selected
     * @param pageNumber Number of the page selected
     */
    function goToPage (pageNumber) {
      updateStorePagination(pageNumber)
      requestResults()
    }

    /**
     * @description Sets values of pagination in the store.
     * @param pageNumber number of current page.
     */
    function updateStorePagination (pageNumber) {
      store.commit({
        type: 'SET_PAGINATION',
        size: cardsCount.value,
        page: pageNumber
      })
    }

    /**
     * @description Dispatches a method in the store to get results.
     */
    function requestResults () {
      store.dispatch({
        type: 'getResultsSearch'
      }).then(
        (response) => {
          trackPagination(response.searchId, store.state.pagination.page)
        }
      )
    }

    /**
     * @description Opens modal to show filters
     */
    function openFilters () {
      store.commit({
        type: 'TOGGLE_API_LOADING',
        isAPILoading: true
      })
      store.dispatch({
        type: 'getFilters'
      }).then(() => {
        document.fonts.load('18px Circular Std Black').then(() => {
          store.commit({
            type: 'TOGGLE_API_LOADING',
            isAPILoading: false
          })
        })
      })
      store.commit({
        type: 'TOGGLE_VISIBLE_CONTENT',
        hideMainContent: true
      })
    }

    /**
     * @description Parses response from API to a generic object with standarized  properties to
     * be used by card.
     */
    function parseReponse (responseAPI) {
      if (!responseAPI) return []
      let baseUrl = ''

      const response = responseAPI.map((response) => {
        const result = {
          id: response.id,
          date: response.date_created && store.getters.formatDate(response.date_created),
          description: response.description,
          source: response.source,
          title: response.title,
          contentUrl: response.src_url,
          indexItem: response,
          agency: response.agency[0],
          agencyAtFooter: true,
          target: '_blank'
        }

        switch (response.source) {
          case 'intelligence':
            result.storeTitle = 'Intelligence'
            result.contentUrl = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/detail/${result.id}`
            result.target = '_self'
            result.agency = response.agency ? response.agency.join(', ') : response.agency
            break
          case 'persona':
            result.agencyAtFooter = false
            result.storeTitle = 'People'
            result.country = response.country[0]
            result.date = null
            result.subTitle = response.persona_title ? response.persona_title.toUpperCase() : ''
            baseUrl = `${process.env.VUE_APP_PF_URL}profile/${response.id}`
            result.contentUrl = keywordOnPortfolioInformation(response.portfolio_information) ? `${baseUrl}?portfolioInformation=true` : baseUrl
            result.target = '_self'
            break
          case 'news':
            result.description = response.content
            result.descriptionHTML = response.description
            result.storeTitle = 'News'
            result.contentUrl = `${process.env.VUE_APP_NEWS_URL}detailNews/${response.id}`
            result.target = '_self'
            break
          case 'work':
            result.storeTitle = 'Work'
            result.client = response.client ? response.client.join(' ,') : ''
            result.brand = response.brand ? response.brand.join(' ,') : ''
            result.agency = response.agency ? response.agency.join(', ') : response.agency
            result.date = response.date_created && store.getters.formatDate(response.date_created)
            result.contentUrl = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/detailWork/${result.id}`
            result.target = '_self'
            break
          case 'sharepoint':
            result.storeTitle = 'Office 365'
            result.description = response.description || response.summary
            break
          case 'collections':
            result.storeTitle = 'Public collection'
            result.author = store.getters.formatAuthorName(response.author[0].name)
            result.contentUrl = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/detailCollection/${result.id}`
            result.target = '_self'
            break
          case 'gogreyOpportunity':
            result.storeTitle = 'Borderless Opportunities'
            result.author = store.getters.formatAuthorName(response.author[0].name)
            result.date = store.getters.formatDate(response.start_date)
            result.daysCount = store.getters.formatDatePublish(response.date_published)
            result.client = response.potential_client ? response.potential_client : (response.client ? response.client.join(' ,') : '')
            result.project = response.project
            result.contentUrl = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host.replace(/search/g, 'go')}/detailOpportunity/${response.id}`
            result.target = '_self'
            result.descriptionHTML = response.description
            break
          case 'gogreyNews':
            result.description = response.content
            result.descriptionHTML = response.description
            result.storeTitle = 'Go Grey News'
            result.contentUrl = `${process.env.VUE_APP_NEWS_URL}detailNews/${response.id}`
            result.target = '_self'
            break
          case 'gogreyHighlight':
            result.storeTitle = 'HIGHLIGHT'
            result.descriptionHTML = response.description
            result.subTitle = response.asset_type === 'PostHighlight' ? '' : (response.subtitle ? response.subtitle.toUpperCase() : '')
            break
          case 'gogreySpotlight':
            result.storeTitle = 'OFFICE SPOTLIGHT'
            result.descriptionHTML = response.description
            result.contentUrl = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host.replace(/search/g, 'go')}/detailSpotlight/${response.id}?application=Search&applicationSection=ResultCard`
            result.target = '_self'
            break
        }
        return result
      })
      return response
    }

    /**
     * @description Validate if search value is included on portfolio information keywords
     * @param portfolioInformation array with portfolio information keywords
     */
    function keywordOnPortfolioInformation (portfolioInformation) {
      let keywordFound = false
      if (store.state.searchValue && portfolioInformation.length > 0) {
        for (let index = 0; index < portfolioInformation.length; index++) {
          if (portfolioInformation[index].toLowerCase().includes(store.state.searchValue.toLowerCase())) {
            keywordFound = true
            break
          }
        }
      }
      return keywordFound
    }

    /**
     * @description Launches a search applying no filters.
     */
    function searchWithoutFilters () {
      store.commit({
        type: 'UPDATE_URL'
      })

      if (store.state.defaultFlow === 'salesForce') {
        store.dispatch({
          type: 'getSalesForceInformation',
          idOpportunity: route.params.idOpportunity
        }).then(() => {
          store.dispatch({
            type: 'getResultsSearch'
          })
        })
      } else {
        store.dispatch({
          type: 'getResultsSearch'
        })
      }
    }

    /**
     * @description Returns an array of filters got from a given url.
     * @param query query with information of filters.
     */
    function getFiltersFromUrl (query) {
      const filterResults = query.split(',,').map(item => ({
        category: item.split('::')[0].replace('%26', '&'),
        description: item.split('::')[1].replace('%26', '&').trim()
      }))
      return filterResults
    }

    /**
     * @description Updates list of selected filters based on a given array of filters.
     * @param filters array of filters to add to selected ones.
     */
    function parseFiltersFromUrl (filters) {
      filters.forEach(filter => {
        store.dispatch({
          type: 'addNewSelectedFilter',
          category: filter.category,
          description: filter.description
        })
      })
    }

    /**
     * @description Parses query got from url to apply filters found in it.
     * @param query query with information of filters.
     */
    function applyFiltersFromUrl (query) {
      const filterResults = getFiltersFromUrl(query)
      store.commit({
        type: 'BUILD_QUERY_FILTER',
        filters: filterResults
      })
      store.dispatch({
        type: 'getFilters'
      }).then(() => {
        try {
          parseFiltersFromUrl(filterResults)
          filterPillListRef.value.updateFilterList()
          store.commit({
            type: 'UPDATE_TEMP_QUERY_FILTER'
          })
          store.dispatch({
            type: 'applyFilters'
          })
        } catch (error) {
          console.error(error)
          searchWithoutFilters()
        }
      })
    }

    /**
     * @description Go to home page
     */
    function goToHome () {
      if (configuration.showSaveSearch && store.state.saveSearch.isEditingSearch) {
        resetEditingSearch()
      }
      const route = process.env.VUE_APP_API_ENV === 'prd' ? 'https://go.grey.com' : `https://${process.env.VUE_APP_API_ENV}-go.grey.com`
      window.open(route, '_self')
    }

    /**
     * @description Reset information about search being edited.
     */
    function resetEditingSearch () {
      store.commit({
        type: 'SET_SEARCH_EDITING_INFORMATION',
        isEditingSearch: false,
        editingSearchInfo: {
          name: '',
          source: '',
          searchTerms: '',
          searchFilters: [],
          orderBy: ''
        }
      })
      store.commit({
        type: 'SET_SEARCH_EDITING_HAS_CHANGED',
        editingSearchHasChanged: false
      })
    }

    /**
     * @description Emit when collection modal is opened and stop other clicks
     * @param id of the card that will be added.
     * @param flag to control open or close modal.
     */
    function toogleCollectionsModal (id, flag) {
      if (id) addCollectionModalID.value = id
      store.commit({
        type: 'SET_COLLECTION_INFO',
        id: id,
        flag: flag
      })
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag to indicate if open or close
     */
    function toggleLikesModal (flag) {
      likesModalVisible.value = flag
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Go Back to collection Modal and reload collections
     */
    function backToCollectionModal () {
      store.dispatch({
        type: 'getCollections',
        ids: [addCollectionModalID.value]
      }).then(
        (response) => {
          toogleCollectionsModal(addCollectionModalID.value, true)
          toggleCreateCollectionsModal(false)
        }
      )
    }

    /**
     * @description Go to Create collectionModal
     */
    function forwardToCreateCollectionModal () {
      toogleCollectionsModal(null, false)
      toggleCreateCollectionsModal(true)
    }

    /**
     * @description Sets the likesModalID value and open the likes modal
     * @param itemID To indicate the ID ot the item that was selected by user
     */
    function openLikesModal (itemID) {
      likesModalID.value = itemID
      toggleLikesModal(true)
    }

    /**
     * @description open add to my portfolio modal confirmation.
     * @param {mode} string to indicate if message will be about removing or adding an item.
     */
    function openAddToPortfolioModal (mode) {
      portfolioModal.value.open(mode)
    }

    /**
     * @description Open delete item confirmation modal
     * @param itemId id of item to delete
     * @param itemSource id of item to delete
     */
    function openDeleteModal (itemId, itemSource) {
      deleteItemSource.value = itemSource
      deleteItemModal.value.open(itemId)
    }

    /**
     * @description Open save search modal
     */
    function openSaveSearchModal () {
      saveSearchModalRef.value.open()
    }

    /**
     * @description show toast with delete item fail message.
     */
    function showDeleteErrorToast () {
      deleteErrorToast.value = true
      setTimeout(() => {
        deleteErrorToast.value = false
      }, 5000)
    }

    /**
     * @description Dispatch an action to refresh the cards.
     */
    function dispatchSearch () {
      store.commit({
        type: 'SET_REFRESH_CARDS_FLAG',
        refreshCardsIdsNeeded: true
      })

      store.dispatch({
        type: 'getResultsSearch'
      })
    }

    /**
     * @description Delete current saved search
     */
    function deleteSavedSearch () {
      store.dispatch({
        type: 'deleteSavedSearch',
        searchId: store.state.saveSearch.searchId
      }).then((response) => {
        if (response.success) {
          store.commit({
            type: 'SET_SEARCH_SAVED_INFORMATION',
            searchSaved: false,
            searchId: ''
          })
          resetEditingSearch()
        } else {
          showDeleteErrorToast()
        }
      }).catch((error) => {
        console.error(error)
        showDeleteErrorToast()
      })
    }

    /**
     * @description Save changes made on edited search
     */
    function saveEditedSavedSearch () {
      store.dispatch({
        type: 'saveEditedSavedSearch'
      }).then((response) => {
        if (response.success) {
          store.commit({
            type: 'SET_SEARCH_EDITING_HAS_CHANGED',
            editingSearchHasChanged: false
          })
        } else {
          showDeleteErrorToast()
        }
      }).catch((error) => {
        console.error(error)
        showDeleteErrorToast()
      })
    }

    /**
     * @description Emits an event to open form to upload files.
     */
    function openForm () {
      window.open(`${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/uploadFile`, '_self')
    }

    /**
     * @description Toggles visibility of side bar.
     * @param {Bool} isVisible flag indicating if side bar will be visible.
     * @param {Array} itemToShow item to show in the side bar.
     */
    function togglePreviewVisibility (isVisible, itemToShow) {
      if (isVisible && itemToShow) {
        if (!previewItem.value || previewItem.value.id !== itemToShow.id) {
          isPreviewVisible.value = false
          nextTick(() => {
            additionalSourceClass.value = (itemToShow.source === 'news' || itemToShow.source === 'persona') ? itemToShow.source : itemToShow.source === 'gogreyNews' ? 'news' : ''
            isPreviewVisible.value = true
            previewItem.value = itemToShow
            previewItemContentUrl.value = resultItems.value.find(item => item.id === itemToShow.id).contentUrl
          })
        }
      } else {
        isPreviewVisible.value = false
        previewItem.value = null
        additionalSourceClass.value = ''
        previewItemContentUrl.value = ''
      }
    }

    /**
     * @description Send signal to update the search input value
     */
    function updateSearchInput (value) {
      isSearchTermUpdated.value = true
      setTimeout(() => {
        isSearchTermUpdated.value = false
      }, 0)
    }

    /**
     * @description Switches the view mode between list and grid
     */
    function toggleView () {
      store.commit({
        type: 'SET_GRID_VIEW',
        isGridView: !store.state.isGridView
      })

      store.commit({
        type: 'UPDATE_URL'
      })

      store.commit({
        type: 'TOGGLE_SHOW_DATA',
        toggle: false
      })

      setTimeout(() => {
        store.commit({
          type: 'TOGGLE_SHOW_DATA',
          toggle: true
        })
      }, 1000)
    }

    /**
     * @description Updates the collection data to check if it contains item id
     * @param collectionIndex index of the collection to update
     */
    function updateCollectionContainId (collectionIndex) {
      store.commit({
        type: 'ADD_REMOVE_ITEM_FROM_COLLECTION',
        positionCollection: collectionIndex
      })
    }

    /**
     * @description Open/Close highLight modal
     * @param flag flag to indicate if open or close
     * @param highLightParam active highLight
     */
    function toggleHighLightModal (flag, highLightParam = {}) {
      if (flag) {
        highLightObject.value = highLightParam
        highLightModalVisible.value = true
      } else {
        highLightModalVisible.value = false
      }
    }

    return {
      store,
      route,
      maxPages,
      configuration,
      errorMessageToast,
      deleteErrorToast,
      likesModalVisible,
      likesModalID,
      isPreviewVisible,
      previewItem,
      additionalSourceClass,
      previewItemContentUrl,
      filterPillListRef,
      cardsWrapperRef,
      portfolioModal,
      saveSearchModalRef,
      deleteItemModal,
      resultItems,
      keyWord,
      displayFiltersButton,
      showSaveSearchOption,
      showEditingSearchOption,
      goToPage,
      openFilters,
      goToHome,
      toogleCollectionsModal,
      toggleLikesModal,
      openLikesModal,
      openAddToPortfolioModal,
      openDeleteModal,
      openSaveSearchModal,
      showDeleteErrorToast,
      dispatchSearch,
      deleteSavedSearch,
      saveEditedSavedSearch,
      openForm,
      togglePreviewVisibility,
      updateSearchInput,
      isSearchTermUpdated,
      toggleView,
      showGridView,
      cardsCount,
      deleteItemSource,
      allowSelectAllCards,
      toggleCreateCollectionsModal,
      backToCollectionModal,
      createCollectionModalVisible,
      forwardToCreateCollectionModal,
      addCollectionModalID,
      isContentLoaded,
      updateCollectionContainId,
      highLightModalVisible,
      highLightObject,
      toggleHighLightModal,
      minHeightCardsWrapper
    }
  }
}
</script>
