<template>
  <section class="containerMenu containerFilterPerApp" v-click-away="clickOutside">
    <button class="containerMenu__button" @click="toogleMenu">
      <span class="containerMenu__button--label">{{filterLabelTitle(selectedFilter)}}</span>
      <span class="grey-icon-arrow-minimize" :class="{'opened': toogleFilterPerApp}"></span>
    </button>
    <transition enter-active-class="menuAnimationIn" leave-active-class="menuAnimationOut">
      <ul class="containerMenu__list" v-if="toogleFilterPerApp">
        <li class="containerMenu__list--option" v-for="(item, index) in messages.filterPerApp"
            :key="index" @click="selectFilterPerApp(item, true)" v-show="item.title !== selectedFilter"
            :class="{'noMobile': !store.state.isMobile}">
          {{filterLabelTitle(item.title)}}
        </li>
      </ul>
    </transition>
  </section>
</template>

<script>
import { onMounted, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import filterPerApp from './filterPerApp.json'

export default {
  name: 'filterPerApp',
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const messages = reactive(filterPerApp)
    const toogleFilterPerApp = ref(false)
    const selectedFilter = ref(null)

    onMounted(() => {
      setUpFilterPerApp()
    })

    /**
     * @description Filter label in order to show on view (Keeping original label on under process)
     * @param title Source
     */
    const filterLabelTitle = computed(() => (title) => {
      return title === 'Office365' ? 'Office 365' : title
    })

    /**
     * @description Sets initial configuration as source shown by default.
     */
    function setUpFilterPerApp () {
      messages.filterPerApp = store.state.defaultFlow === 'salesForce' ? messages.filterPerApp.filter(filterApp => ['People', 'Intelligence', 'Work'].includes(filterApp.title)) : messages.filterPerApp
      const defaultFlowParam = store.state.defaultFlow === 'salesForce' ? route.query.s : store.state.defaultFlow
      if (defaultFlowParam) {
        const defaultSource = messages.filterPerApp.find(message => message.title.toLowerCase() === defaultFlowParam.toLowerCase())
        if (defaultSource) {
          selectFilterPerApp(defaultSource, false)
        }
      } else {
        selectedFilter.value = route.query.s || store.state.source
      }
    }

    /**
     * @description Show or hide menu
     */
    function toogleMenu () {
      toogleFilterPerApp.value = !toogleFilterPerApp.value
    }

    /**
     * @description Hide menu whenever user click outside of component
     */
    function clickOutside () {
      toogleFilterPerApp.value = false
    }

    /**
     * @description Event when user select a filter
     * @param item Option selected by user (JSON)
     * @param launchSearch flag to indicate if a new search needs to be made.
     * @event dispatch getResultsSearch to get data
     */
    function selectFilterPerApp (item, launchSearch) {
      selectedFilter.value = item.title
      if (launchSearch) toogleMenu()

      store.commit({
        type: 'SET_SOURCE_TO_SEARCH',
        source: item.title
      })

      store.commit({
        type: 'SET_EXACT_SEARCH',
        exactSearch: false
      })

      if (launchSearch) {
        store.commit({
          type: 'CLEAN_PAGINATION'
        })

        store.commit({
          type: 'SET_REFRESH_CARDS_FLAG',
          refreshCardsIdsNeeded: true
        })

        store.dispatch({
          type: 'getResultsSearch'
        })
      }
    }

    return {
      store,
      route,
      messages,
      toogleFilterPerApp,
      selectedFilter,
      toogleMenu,
      clickOutside,
      selectFilterPerApp,
      filterLabelTitle
    }
  }
}
</script>
