
<template>
  <section class="containerPills" :class="{'inResultsView': view}" v-if="areSelectedFilters">
    <ul class="containerPills__pillList">
      <span class="containerPills__pillList--label">Filtered by</span>
      <li v-for="(filter, index) in filterList"
          :key="index"
          :data-index="index"
          class="containerPills__pillList--pill">
        <filterPill :label="filter.description" @clearPill="clearFilter(filter)"/>
      </li>
      <li class="containerPills__pillList--clearButton">
        <button class="button" @click="clearAllFilters()">
          Clear All
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import filterPill from '@/components/filterPill/filterPill'

export default {
  name: 'filterPillList',
  props: ['view'],
  components: {
    filterPill
  },
  emits: ['calculateNumberOfColumns'],
  setup (props, { emit }) {
    const store = useStore()

    /**
     * @description Indicates if there are filters to show.
     */
    const areSelectedFilters = computed(() => {
      return filterList.value.length > 0
    })

    /**
     * @description Filters to show.
     */
    const filterList = computed(() => {
      return props.view ? store.state.filters.selectedFilters : store.state.filters.temporaryFilters
    })

    onMounted(() => {
      updateFilterList()
    })

    /**
     * @description Updates list of filters taking those previously selected by the user.
     */
    function updateFilterList () {
      store.commit({
        type: 'UPDATE_SELECTED_FILTERS',
        temporaryFilters: store.state.filters.selectedFilters
      })
    }

    /**
     * @description Removes all selected filters.
     */
    function clearAllFilters () {
      store.commit({
        type: 'REMOVE_QUERY_FILTER'
      })
      store.commit({
        type: 'UPDATE_TEMP_QUERY_FILTER'
      })
      store.dispatch({
        type: 'getFilters'
      }).then(() => {
        emit('calculateNumberOfColumns')
      })
      if (props.view) {
        updateFiltersAutomatically()
      }
    }

    /**
     * @description Removes a given filter from selected filters list.
     */
    function clearFilter (deletedFilter) {
      store.commit({
        type: 'REMOVE_QUERY_FILTER',
        item: deletedFilter
      })
      store.commit({
        type: 'UPDATE_TEMP_QUERY_FILTER'
      })
      store.dispatch({
        type: 'getFilters',
        keepCategory: true
      }).then(() => {
        emit('calculateNumberOfColumns')
      })

      if (props.view) {
        updateFiltersAutomatically()
      }
      emit('calculateNumberOfColumns')
    }

    /**
     * @description Update filter when user remove or clear all since results view
     */
    function updateFiltersAutomatically () {
      store.commit({
        type: 'CLEAN_PAGINATION'
      })
      store.commit({
        type: 'UPDATE_FINAL_FILTERS',
        selectedFilters: store.state.filters.temporaryFilters
      })
      store.dispatch({
        type: 'applyFilters'
      })
    }

    return {
      store,
      areSelectedFilters,
      filterList,
      clearAllFilters,
      clearFilter,
      updateFilterList
    }
  }
}
</script>
