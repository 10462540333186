<template>
  <div class="skeletonResultsContainer">
    <div :class="isGridView ? 'skeletonResultsContainer__gridView' : 'skeletonResultsContainer__listView'">
      <div class="skeletonResultsContainer__card" v-for="card in cardsCount" :key="card">
        <div class="cardContent">
          <div class="cardContent__image"></div>
          <div class="cardContent__data">
            <div class="cardContent__data--main">
              <div class="dataMain__header">
                <div class="dataMain__header--source"></div>
                <div class="dataMain__header--title"></div>
              </div>
              <div class="dataMain__description">
                <div class="dataMain__description--line" v-for="item in itemIterations" :key="item"></div>
              </div>
              <div class="dataMain__icon"></div>
            </div>
            <div class="cardContent__data--bottom">
              <div class="dataBottom__left">
                <div class="dataBottom__left--agency"></div>
                <div class="dataBottom__left--date"></div>
              </div>
              <div class="dataBottom__right">
                <div class="dataBottom__right--icon" v-for="item in itemIterations" :key="item"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'skeletonResults',
  props: {
    cardsCount: {
      type: Number
    },
    isGridView: {
      type: Boolean
    }
  },
  setup (props) {
    const itemIterations = 3

    return {
      itemIterations
    }
  }
}
</script>
