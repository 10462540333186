<template>
  <a class="containerSearchCard" :class="[resultItem.source , {'selected': isCardSelected}, {'gridView': isGridView}]"
    @click="clickOncard()"
    :title="tooltipMsg"
    @mouseover="toggleCheckBoxVisibility(true)"
    @mouseleave="toggleCheckBoxVisibility(false)"
    :href="resultItem.contentUrl"
    :target="resultItem.target"
  >
    <section class="containerSearchCard__containerImage" ref="images">
      <genericCheckBox
        class="containerSearchCard__containerImage--check"
        v-if="!store.state.cardIdsLoading && allowCheckBox"
        @change="toggleCardSelection($event)"
        v-model="checkBoxModel">
      </genericCheckBox>
      <cardImages :item="resultItem.indexItem" :indexItem="index"></cardImages>
    </section>
    <div class="containerSearchCard__box">
      <span v-if="resultItem.storeTitle" class="containerSearchCard__box--source">{{resultItem.storeTitle}}</span>
      <span v-if="resultItem.title" class="containerSearchCard__box--title box-vertical" :title="resultItem.title">{{resultItem.title}}</span>
      <span v-if="resultItem.subTitle" class="containerSearchCard__box--subTitle">{{resultItem.subTitle}}</span>
      <span v-if="resultItem.author && resultItem.source !== 'gogreyOpportunity'" class="containerSearchCard__box--author">By {{resultItem.author}}</span>
      <span v-if="resultItem.project && resultItem.source === 'gogreyOpportunity'" class="containerSearchCard__box--client box-vertical" :title="resultItem.project">{{resultItem.project}}</span>

      <span class="containerSearchCard__box--bottomCard">
        <template v-if="resultItem.agencyAtFooter">
          <span v-if="resultItem.date" class="date"><span v-if="resultItem.source === 'gogreyOpportunity'" class="date--title">Start Date: </span>{{resultItem.date}}</span>
          <span v-if="resultItem.agency" class="agency" :title="resultItem.agency" @contextmenu.prevent>{{resultItem.agency}}</span>
        </template>
        <template v-else>
          <span v-if="resultItem.country" class="country"><span class="grey-icon-map-pin"></span>{{resultItem.country}}</span>
          <span v-if="resultItem.agency" class="agencyGrey" @contextmenu.prevent>{{resultItem.agency}}</span>
        </template>
      </span>
      <span v-if="isDescriptionEmpty" class="containerSearchCard__box--tempDescription">
        <span class="client">Client: {{resultItem.client}}</span>
        <span class="brand">Brand: {{resultItem.brand}}</span>
        <span class="agency">Agency: {{resultItem.agency}}</span>
      </span>
      <template v-else-if="showCardDescription">
        <span class="containerSearchCard__box--description box-vertical">{{resultItem.description}}</span>
      </template>
      <contentHTML :content="resultItem.descriptionHTML" v-else-if="resultItem.source !== 'persona' && resultItem.source !== 'collections' && resultItem.source !== 'gogreyOpportunity'" class="containerSearchCard__box--description box-vertical"/>
      <div class="containerSearchCard__box--showMore" v-if="resultItem.source !== 'collections'">
        <div class="previewButton">
          <button title="Preview" @click="launchPreview($event)" v-if="isPreviewAllowed" class="grey-icon-preview previewButton__button" @contextmenu.prevent></button>
        </div>
        <optionsCard
          @openCollectionsModal="openCollectionsModal"
          @openAddToPortfolioModal="openAddToPortfolioModal"
          @openDeleteModal="openDeleteModal"
          :id="resultItem.id"
          :source="resultItem.source"
          :index="index">
        </optionsCard>
      </div>
      <reactionsBox class="containerSearchCard__box--reactions"
        :itemId="resultItem.id"
        likesDetail
        @openLikesModal="openLikesModal(resultItem.id)"
      />
      <span class="containerSearchCard__box--daysCount" v-if="resultItem.source === 'gogreyOpportunity' && resultItem.daysCount">Published {{ resultItem.daysCount }}</span>
    </div>
  </a>
</template>

<script>
import { onMounted, computed, watch, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import reactionsBox from '@/components/reactionsBox/reactionsBox'
import contentHTML from '@/components/contentHTML/contentHTML'
import optionsCard from '@/components/optionsCard/optionsCard'
import cardImages from '@/components/cardImages/cardImages'
import { trackDocument } from '@/utils/azureAnalytics'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'

export default {
  name: 'cardResult',
  props: {
    resultItem: {
      type: Object
    },
    tooltipMsg: {
      type: String
    },
    index: {
      type: Number
    },
    showCheckBoxOnCard: {
      type: Boolean
    },
    isGridView: {
      type: Boolean
    }
  },
  components: {
    contentHTML, reactionsBox, optionsCard, genericCheckBox, cardImages
  },
  emits: ['clickOnCard', 'openCollectionsModal', 'openAddToPortfolioModal', 'openDeleteModal', 'openLikesModal', 'clickOnPreview', 'openHighlightModal'],
  setup (props, { emit }) {
    const store = useStore()
    const showCheckBox = ref(false)
    const images = ref(null)
    const checkBoxModel = ref(false)

    /**
     * @description Indicates if cards has no description and is from work source
     */
    const isDescriptionEmpty = computed(() => {
      return props.resultItem.source === 'work' && !props.resultItem.description
    })

    /**
     * @description Property to check if card is already selected
     */
    const isCardSelected = computed(() => {
      return setDefaultValueToCheck()
    })

    /**
     * @description Indicates if preview icon should be visible
     */
    const isPreviewAllowed = computed(() => {
      return (['work', 'intelligence', 'news', 'persona', 'gogreyNews'].includes(props.resultItem.source))
    })

    /**
     * @description Checks if description container should be displayed
     */
    const showCardDescription = computed(() => {
      return props.resultItem.source !== 'news' && props.resultItem.source !== 'persona' && props.resultItem.source !== 'collections' && props.resultItem.source !== 'gogreyOpportunity' && props.resultItem.source !== 'gogreyNews'
    })

    /**
     * @description Indicates if select checkBox is allowed
     */
    const allowCheckBox = computed(() => {
      const isFilterBySourceInSearch = store.state.source !== 'All sites' && store.state.currentSite === 'SEARCH_SITE'
      const allowDisplayCheckBox = store.state.isMobileResolution
        ? props.showCheckBoxOnCard && (store.state.searchValue || store.state.filters.selectedFilters.length || isFilterBySourceInSearch)
        : ((store.state.searchValue || store.state.filters.selectedFilters.length || isFilterBySourceInSearch) && showCheckBox.value)
      return allowDisplayCheckBox
    })

    onMounted(() => {
      removesDefaultClick()
      if (store.state.cardsSelectedIds[props.resultItem.id]) {
        toggleCheckBoxVisibility(true)
      }
    })

    /**
     * @description Updates the visibility of checbox based on the use of select all checbox.
     * @param cardsSelectedIds object with ids of cards selected.
     */
    watch(() => store.state.cardsSelectedIds, (cardsSelectedIds) => {
      toggleCheckBoxVisibility(cardsSelectedIds && cardsSelectedIds[props.resultItem.id])
    })

    /**
     * @description handle card click event
    */
    function clickOncard () {
      trackCardClicked()
      saveScrollPostition()
      if (navigator.vendor.toLocaleLowerCase().includes('apple' && props.resultItem.target === '_self')) {
        document.body.style.opacity = 0
      }
      if (props.resultItem.source === 'gogreyHighlight') emit('openHighlightModal', { highlightId: props.resultItem.id, title: props.resultItem.title })
    }

    /**
     * @description Open the detail page in a new window
    */
    function trackCardClicked () {
      trackDocument(store.state.dataCards.searchId, props.resultItem.id, props.index)
    }

    /**
     * @description Open the detail page in a new window
    */
    function saveScrollPostition () {
      const appElement = document.querySelector('#app #app')
      localStorage.setItem('scrollTop', appElement.scrollTop)
    }

    /**
     * @description Dispatch emit when collections modal has been opened.
     * @param event of DOM
     * @param id from card that will be added to the collection
     */
    function openCollectionsModal (id, flag) {
      emit('openCollectionsModal', id, flag)
    }

    /**
     * @description Dispatch emit to open add to my portfolio modal confirmation.
     * @param {mode} string to indicate if message will be about removing or adding an item.
     */
    function openAddToPortfolioModal (mode) {
      emit('openAddToPortfolioModal', mode)
    }

    /**
     * @description Dispatch emit to open delete work item modal confirmation.
     */
    function openDeleteModal () {
      emit('openDeleteModal', props.resultItem.id, props.resultItem.source)
    }

    /**
     * @description Emit open likes modal
     * @param itemID Id of the current item
    */
    function openLikesModal (itemID) {
      emit('openLikesModal', itemID)
    }

    /**
     * @description Checks if cards current status is already selected or not.
     */
    function setDefaultValueToCheck () {
      const flag = store.state.cardsSelectedIds[props.resultItem.id]
      checkBoxModel.value = flag
      return flag
    }

    /**
     * @description Toggles visibility of the checkbox to selected the cards.
     * @param {isVisible} boolean to know if checkbox will be visible or not.
     */
    function toggleCheckBoxVisibility (isVisible) {
      if (!props.showCheckBoxOnCard || (!isVisible && store.state.cardsSelectedIds[props.resultItem.id])) {
        return
      }
      showCheckBox.value = isVisible
      nextTick(() => {
        if (showCheckBox.value) {
          removesDefaultClick()
          setDefaultValueToCheck()
        }
      })
    }

    /**
     * @description Removes click listener from anchor.
     */
    function removesDefaultClick () {
      let element = null

      if (images.value) {
        element = images.value.getElementsByClassName('containerSearchCard__containerImage--check')[0]
      }

      if (!element) {
        return
      }

      if (!element.getAttribute('prevented')) {
        element.addEventListener('click', (event) => event.preventDefault(), true)
      }
      element.setAttribute('prevented', true)
    }

    /**
     * @description Toggles the selection of a cards between selected or deselected.
     * @param {isCardSelected} boolean to indicate if card is now selected or not.
     */
    function toggleCardSelection (isCardSelected) {
      store.commit({
        type: 'TOGGLE_CARD_SELECTION',
        id: props.resultItem.id,
        isSelected: isCardSelected
      })
    }

    /**
     * @description Launches a click event in preview icon.
     * @param event DOM event
     */
    function launchPreview (event) {
      event.preventDefault()
      emit('clickOnPreview', props.resultItem)
    }

    return {
      store,
      showCheckBox,
      isDescriptionEmpty,
      isCardSelected,
      isPreviewAllowed,
      images,
      checkBoxModel,
      openCollectionsModal,
      openAddToPortfolioModal,
      openDeleteModal,
      openLikesModal,
      setDefaultValueToCheck,
      toggleCheckBoxVisibility,
      toggleCardSelection,
      launchPreview,
      showCardDescription,
      allowCheckBox,
      clickOncard
    }
  }
}
</script>
