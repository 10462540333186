<template>
  <transition
    name="no-results-page"
    enter-active-class="animated fadeIn">
    <section class="containerNoResultsMsg">
      <div class="containerNoResultsMsg__ImgContainer">
        <img class="containerNoResultsMsg__ImgContainer--Image" :src="require(`@/assets/${messages.noResultsMsg[0].image}`)" alt="No search resuts" @load="imageLoaded = true" v-show="imageLoaded">
      </div>
      <div class="containerNoResultsMsg__Text" v-if="imageLoaded">
        <span class="containerNoResultsMsg__Text--Title">{{messages.noResultsMsg[0].title}}</span>
      </div>
    </section>
  </transition>
</template>

<script>
import { reactive, ref } from 'vue'
import messagesFile from './noResultsMessage.json'

export default {
  name: 'noResultsMessage',
  setup (props) {
    const messages = reactive(messagesFile)
    const imageLoaded = ref(false)

    return {
      messages,
      imageLoaded
    }
  }
}
</script>
