<template>
  <section class="containerCategoryTab" :class="{'selected': category.isSelected}" ref="containerCategoryTab">
    <section class="containerCategoryTab__header" @click="selectCategoryFilter(index)">
      <span class="grey-icon-success-upload" :class="{'visible': isCategoryFiltered(category.description)}"></span>
      <span class="containerCategoryTab__header--label">{{store.getters.getFilterCategoryName(category.description)}}</span>
      <span class="containerCategoryTab__header--counter">{{category.total}}</span>
      <span v-if="isModalMobile" class="grey-icon-arrow-minimize" :class="{opened: categoryOpened}"></span>
    </section>
    <section class="containerCategoryTab__content" v-if="categoryOpened && isModalMobile">
      <filterList ref="filterListRef" :isModalMobile="isModalMobile"></filterList>
    </section>
  </section>
</template>

<script>
import { watch, ref } from 'vue'
import { useStore } from 'vuex'
import filterList from '@/components/filterList/filterList'

export default {
  name: 'filterCategoryTab',
  props: ['category', 'index', 'isModalMobile'],
  components: {
    filterList
  },
  setup (props) {
    const store = useStore()
    const categoryOpened = ref(props.category.isSelected)
    const containerCategoryTab = ref(null)
    const filterListRef = ref(null)

    /**
      * @description Sets category as closed if the category is not selected.
      * @param boolean {isSelected} flag to know if the category has been selected.
      */
    watch(() => props.category.isSelected, (isSelected) => {
      categoryOpened.value = isSelected
    })

    /**
     * @description Sets clicked category as the current selected one.
     */
    function selectCategoryFilter (index) {
      if (props.isModalMobile) {
        setTimeout(() => {
          containerCategoryTab.value.firstElementChild.scrollIntoView()
        }, 10)
      }
      store.commit({
        type: 'SELECT_CATEGORY_FILTER',
        selectedIndex: index
      })

      toggleOpenedCategory()
    }

    /**
     * @description Toggles category to be oppened or closed.
     */
    function toggleOpenedCategory () {
      if (!props.isModalMobile && categoryOpened.value) return false
      categoryOpened.value = !categoryOpened.value
      store.commit({
        type: 'SET_CATEGORY_SELECTED',
        isSelected: categoryOpened.value,
        index: props.index
      })
    }

    /**
     * @description Return true or false depending on if catergory is the current selected one.
     * @param {categoryName} name of the category tfor checking if is the current selected one.
     */
    function isCategoryFiltered (categoryName) {
      return store.state.filters.temporaryFilters.some(filter => filter.category === categoryName)
    }

    /**
     * @description Calculates the number of columns
     */
    function calculateNumberOfColumns () {
      if (categoryOpened.value) filterListRef.value.calculateNumberOfColumns()
    }

    return {
      store,
      categoryOpened,
      containerCategoryTab,
      selectCategoryFilter,
      isCategoryFiltered,
      filterListRef,
      calculateNumberOfColumns
    }
  }
}
</script>
