<template>
  <section class="containerStaticAutocomplete">
    <section class="containerAutocomplete__inputSearch" :class="{animate: animateOnShow}" ref="containerStaticInput">
      <section class="containerAutocomplete__inputSearch--input" :class="{'focus': searchTerm}">
        <section class="containerInput">
          <span class="grey-icon-search"></span>
          <input class="containerInput__input"
            type="text"
            ref="inputSearch"
            :placeholder="searchTitle"
            v-model="searchTerm"
            @keydown.enter="applySearch"
            @keydown.up="up"
            @keydown.down="down"
            @keyup="launchSuggestions()"
          />
          <span class="grey-icon-clear" @click="cleanField" v-if="searchTerm"></span>
        </section>
        <section class="containerLines">
          <hr class="containerInput__barAnimated">
          <div class="containerLines__autoLine"></div>
        </section>
      </section>
      <button class="containerAutocomplete__inputSearch--searchButton" @click="applySearch">
        <span class="text">Search</span>
        <span class="grey-icon-search"></span>
      </button>
    </section>
    <section class="containerStaticAutocomplete__options" @mouseleave="resetActiveOver" v-if="searchTerm">
      <transition-staggered
        tag="ul"
        className="containerStaticAutocomplete__options--list"
        type="vertically"
        v-if="showSuggestions"
        ref="optionsList"
        v-click-away="closeSuggestions"
      >
        <li v-for="(item, index) in matches" :key="item.name" :data-index="index"
          @mouseover="removeSelected(index)"
          @click="itemSelected(index)"
          style="-webkit-box-orient: vertical;"
          class="item"
          :class="{'selected': selected === index, 'activeHover': activeHover === index}"
          :title="item.name"
        >
          {{item.name}}
        </li>
      </transition-staggered>
    </section>
  </section>
</template>

<script>
import { onMounted, nextTick, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { trackQuery } from '@/utils/azureAnalytics'

export default {
  name: 'staticAutoComplete',
  props: {
    searchTitle: {
      type: String
    },
    isSearchTermUpdated: {
      type: Boolean
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const isApplySearch = ref(null)
    const searchTerm = ref(store.getters.searchTermFromUrl(route.query))
    const requestControl = ref(null)
    const itemHeight = ref(89)
    const matches = ref([])
    const selected = ref(-1)
    const activeHover = ref(null)
    const showSuggestions = ref(false)
    const desactivateRequest = ref(false)
    const animateOnShow = ref(Boolean(getComputedStyle(document.body).getPropertyValue('--initialWidthAutoComplete')))
    const numberAnimationsPending = ref(4)
    const containerStaticInput = ref(null)
    const inputSearch = ref(null)
    const optionsList = ref(null)
    const previousInputValue = ref('')
    const intervalProgress = ref(null)

    onMounted(() => {
      addAnimationsListeners()
      inputSearch.value.focus()
    })

    /**
    * @description Updates search term based on query got from url, also prevent looking for suggestions.
    * @param newValue current value of url.
    * @param oldValue previous valur of url.
    */
    watch(() => route, (route) => {
      desactivateRequest.value = true
      searchTerm.value = store.getters.searchTermFromUrl(route.query)
      setTimeout(() => {
        desactivateRequest.value = false
      }, 0)
    })

    /**
    * @description Updates search term based on prop value change
    */
    watch(() => props.isSearchTermUpdated, (isSearchTermUpdated) => {
      if (isSearchTermUpdated) {
        searchTerm.value = store.state.searchValue
      }
    })

    /**
     * @description Add listener to parent element to detect when all animations have finished.
     */
    function addAnimationsListeners () {
      containerStaticInput.value.addEventListener('animationend', (event) => {
        numberAnimationsPending.value--
        if (numberAnimationsPending.value) {
          animateOnShow.value = false
        }
      })
    }

    /**
     * @description Launches function to get search suggestion for user's input.
    */
    function launchSuggestions () {
      nextTick(() => {
        if (previousInputValue.value !== inputSearch.value.value) {
          previousInputValue.value = inputSearch.value.value
          searchTerm.value = previousInputValue.value
          launchSearch(previousInputValue.value)
        }
      })
    }

    /**
     * @description Launches logic in the store to make an API request to get suggestions.
     * @param searchTermParam term given by the user.
     */
    function launchSearch (searchTermParam) {
      isApplySearch.value = false
      selected.value = selected.value === null ? -1 : selected.value
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTermParam,
        apply: false
      })
      if (searchTermParam.length < 3) {
        matches.value = []
        selected.value = -1
        showSuggestions.value = false
      }
      if (!desactivateRequest.value) {
        searchMatch(searchTermParam)
      }
    }

    /**
     * @description Get match results with typing's user
     * @param searchTermPram term given by the user.
     */
    function searchMatch (searchTermParam) {
      const defaultFlowParam = store.state.defaultFlow === 'salesForce' ? route.query.s : store.state.defaultFlow
      const filterSuggestion = defaultFlowParam ? `filterExpression=source eq '${defaultFlowParam}'&` : ''
      showSuggestions.value = false
      if (searchTermParam.length > 2) {
        clearTimeout(requestControl.value)
        requestControl.value = setTimeout(() => {
          store.dispatch({
            type: 'predictiveSearch',
            filterSuggestion: filterSuggestion
          }).then(
            (response) => {
              if (!response) {
                return
              }
              matches.value = response.data
              showSuggestions.value = isApplySearch.value ? showSuggestions.value : true
            },
            (error) => {
              console.error(error)
            }
          )
        }, 500)
      }
    }

    /**
     * @description Clean input and hide icon
     */
    function cleanField () {
      searchTerm.value = ''
    }

    /**
     * @description Apply search when user click on apply label
     * @event commit Set search value in store
     * @event dispatch Send request to get results
     * @event $Progress.start Start input progess bar
     */
    function applySearch () {
      finishAnimation()
      inputSearch.value.blur()
      selected.value = selected.value === null ? -1 : selected.value
      showSuggestions.value = false
      isApplySearch.value = desactivateRequest.value = true

      if (selected.value !== -1) {
        searchTerm.value = matches.value[selected.value].name
        setTimeout(() => {
          selected.value = -1
          matches.value = []
        })
      }

      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTerm.value,
        apply: true
      })

      store.commit({
        type: 'CLEAN_PAGINATION',
        size: store.state.pagination.size
      })

      store.commit({
        type: 'SET_EXACT_SEARCH',
        exactSearch: false
      })

      store.commit({
        type: 'SET_REFRESH_CARDS_FLAG',
        refreshCardsIdsNeeded: true
      })

      store.commit({
        type: 'SORT_TYPE',
        parameter: 'search.score()',
        order: 'desc'
      })

      initAnimation()

      store.dispatch({
        type: 'getResultsSearch'
      }).then(
        (response) => {
          desactivateRequest.value = false
          setTimeout(() => {
            finishAnimation()
          }, 500)
          trackQuery(response.searchId, searchTerm.value, response['@odata.count'])
        },
        (error) => {
          console.error(error)
        }
      )
    }

    /**
     * @description Select item when user click
     * @param index Index from list
     */
    function itemSelected (index) {
      inputSearch.value.focus()
      selected.value = index
      applySearch()
    }

    /**
     * @description Event dispatch when user press up from keyboard
     */
    function up () {
      if (selected.value === -1) {
        return
      }
      selected.value = activeHover.value ? activeHover.value - 1 : selected.value - 1
      activeHover.value = null
      scrollToItem()
    }

    /**
     * @description Event dispatch when user press down from keyboard
     */
    function down () {
      if (selected.value >= matches.value.length - 1) {
        return
      }
      selected.value = activeHover.value ? activeHover.value + 1 : selected.value + 1
      activeHover.value = null
      scrollToItem()
    }

    /**
     * @description Position scroll if user down or up on list
     */
    function scrollToItem () {
      window.scrollTo(0, selected.value * itemHeight.value)
    }

    /**
     * @description Event dispatch when user perform hover in any item from the list
     * @param index Index item that user is hovering
     */
    function removeSelected (index) {
      selected.value = null
      activeHover.value = index
    }

    /**
     *  @description Event to reset the active over property when mouse is located outside the suggestions container
     */
    function resetActiveOver () {
      selected.value = -1
      activeHover.value = null
    }

    /**
     * @description Begins the animation of the bottom bar.
     */
    function initAnimation () {
      const styles = document.documentElement.style
      let progress = 1
      intervalProgress.value = setInterval(() => {
        progress *= 1.2
        styles.setProperty('--progressBarCounter', `${progress}%`)

        if (progress >= 99) {
          styles.setProperty('--progressBarCounter', '99%')
          clearInterval(intervalProgress.value)
        }
      }, 100)
    }

    /**
     * @description Finishes the animation of the bottom bar.
     */
    function finishAnimation () {
      clearInterval(intervalProgress.value)
      const styles = document.documentElement.style
      styles.setProperty('--progressBarCounter', '100%')
      setTimeout(() => {
        styles.setProperty('--progressBarCounter', '0%')
      }, 300)
    }

    /**
     * @description Method to close the modal of suggestions
     */
    function closeSuggestions () {
      showSuggestions.value = false
    }

    return {
      store,
      route,
      isApplySearch,
      searchTerm,
      requestControl,
      itemHeight,
      matches,
      selected,
      activeHover,
      showSuggestions,
      desactivateRequest,
      animateOnShow,
      numberAnimationsPending,
      containerStaticInput,
      inputSearch,
      optionsList,
      previousInputValue,
      launchSuggestions,
      cleanField,
      applySearch,
      itemSelected,
      up,
      down,
      removeSelected,
      resetActiveOver,
      closeSuggestions
    }
  }
}
</script>
