<template>
  <section class="containerMenu containerSortButton" v-click-away="closeModal" v-if="!store.state.showData || (store.state.dataCards.value && store.state.dataCards.value.length)">
    <button class="containerMenu__button" @click="toggleModal">
      <span class="containerMenu__button--label"> {{currentSortCriteria}} </span>
      <span class="grey-icon-arrow-minimize" :class="{opened: showSortOptions}"></span>
    </button>
    <transition enter-active-class="menuAnimationIn" leave-active-class="menuAnimationOut">
      <ul class="containerMenu__list" v-if="showSortOptions">
        <li class="containerMenu__list--option"
            v-for="item in sortOptions" :key="item.value"
            @click="sortBy(item)" :class="{'selectedSort' : item.isSelected, 'noMobile': !store.state.isMobile}">{{item.name}}</li>
      </ul>
    </transition>
  </section>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import sortButtons from './sortButtons.json'

export default {
  name: 'SortButtons',
  setup (props) {
    const store = useStore()
    const showSortOptions = ref(false)
    const sortOptions = ref(sortButtons[store.state.currentSite].map(options => Object.assign(options, { isSelected: false })))

    /**
     * @description Return the current sort criteria.
     */
    const currentSortCriteria = computed(() => {
      const selectedOption = sortOptions.value.find(option => option.isSelected)
      return selectedOption ? selectedOption.name : ''
    })

    onMounted(() => {
      setInitialSelection()
    })

    /**
     * @description Marks as selected the option got from url.
     */
    function setInitialSelection () {
      sortOptions.value.forEach(option => {
        option.isSelected = option.parameter === store.state.sortType.parameter && option.defaultOrder === store.state.sortType.order
      })
    }

    /**
     * @description Method to sort the results according to the option selected
     * @param Option selected to sort the items
     */
    function sortBy (option) {
      if (option.parameter === store.state.sortType.parameter && option.defaultOrder === store.state.sortType.order) {
        return
      }

      option.isSelected = true

      store.commit({
        type: 'SORT_TYPE',
        parameter: option.parameter,
        order: option.defaultOrder
      })

      store.commit({
        type: 'CLEAN_PAGINATION'
      })

      store.commit({
        type: 'SET_REFRESH_CARDS_FLAG',
        refreshCardsIdsNeeded: true
      })

      store.dispatch({
        type: 'getResultsSearch'
      }).then(
        (response) => {
          showSortOptions.value = false
        },
        (error) => {
          console.error(error)
        }
      )
    }

    /**
     * @description Method to close the modal with the Sort Options
     */
    function closeModal () {
      showSortOptions.value = false
    }

    /**
     * @description Toggles the state of the modal's visibility.
     */
    function toggleModal () {
      showSortOptions.value = !showSortOptions.value
    }

    return {
      store,
      showSortOptions,
      sortOptions,
      currentSortCriteria,
      sortBy,
      closeModal,
      toggleModal
    }
  }
}
</script>
