<template>
  <section class="containerSelectAllCards">
    <section v-if="!store.state.cardIdsLoading" class="containerSelectAllCards__actions">
      <genericCheckBox v-model="modelIsSelected" @change="toggleAllCardsSelection($event)" :indeterminate="isIndeterminate" ref="selectAllCheck" label="Select All"></genericCheckBox>
      <span class="containerSelectAllCards__actions--counter" v-if="store.state.counterSelectedCards">({{store.state.counterSelectedCards}} file<span v-if="areManyCardsSelected">s</span> selected)</span>
      <span class="containerSelectAllCards__actions--modalButton" v-if="store.state.counterSelectedCards" @click="toogleModalCollectionVisibility(true)">
        <span class="grey-icon-add-to-collections button"></span>
        <span class="label">Add To Collection</span>
      </span>
    </section>
    <section v-else class="containerSelectAllCards__spinner">
      <spinner class="selectAllSpinner" />
    </section>
    <addRemoveItemsToCollectionGeneric
      v-if="isCollectionModalOpen"
      @goToNewCollection="forwardToCreateCollectionModal"
      @close="toogleModalCollectionVisibility(false)"
      :cardsId="selectedIds"
      :collectionsList="store.state.collectionsMine"
      @updateCollectionContainId="updateCollectionContainId($event)">
    </addRemoveItemsToCollectionGeneric>
    <createCollectionModalGeneric
      v-if="createCollectionModalVisible"
      :creatingWithItems="true"
      :cardsId="selectedIds"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="backToCollectionModal"
      @close="toggleCreateCollectionsModal(false)"
    ></createCollectionModalGeneric>
    <section class="containerSelectAllCards__mobileModal" :style="{height: [store.state.counterSelectedCards > 0 ? '53px' : '0px']}">
      <span class="containerSelectAllCards__mobileModal--counter">{{store.state.counterSelectedCards}} file<span v-if="areManyCardsSelected">s</span> selected</span>
      <span class="containerSelectAllCards__mobileModal--separator">|</span>
      <span class="grey-icon-add-to-collections containerSelectAllCards__mobileModal--icon" @click="toogleModalCollectionVisibility(true)"></span>
      <span class="grey-icon-close containerSelectAllCards__mobileModal--closeButton" @click="toggleAllCardsSelection(false)"></span>
    </section>
  </section>
</template>

<script>
import { onMounted, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'selectAllCards',
  components: {
    genericCheckBox,
    spinner
  },
  setup (props) {
    const store = useStore()
    const isCollectionModalOpen = ref(false)
    const createCollectionModalVisible = ref(false)
    const selectedIds = ref([])
    const selectAllCheck = ref(null)
    const modelIsSelected = ref(false)

    /**
     * @description Flag to know if there are more than one card selected.
     */
    const areManyCardsSelected = computed(() => {
      return store.state.counterSelectedCards > 1
    })

    /**
     * @description Flag to indicate if all cards are selected or just a couple of them.
     */
    const isIndeterminate = computed(() => {
      return store.state.counterSelectedCards > 0 && store.state.counterSelectedCards !== store.state.dataCards['@odata.count']
    })

    onMounted(() => {
      updateSelectionBasedOnCounter(store.state.counterSelectedCards)
    })

    /**
     * @description Changes the value of select all checbox based on individual selections of each card.
     * @param {newCounter} integer with the total of cards selected.
     */
    watch(() => store.state.counterSelectedCards, (newCounter) => {
      updateSelectionBasedOnCounter(newCounter)
    })

    /**
     * @description Selects or deselects all the cards.
     * @param {allCardsSelected} boolean to indicate if all cards will be selected or not.
     */
    function toggleAllCardsSelection (allCardsSelected) {
      store.commit({
        type: 'TOGGLE_CARDS_SELECTION',
        areAllCardsSelected: allCardsSelected
      })
      document.getElementsByClassName('containerMobileMenu__menu')[0].style.bottom = allCardsSelected ? '68px' : '34px'
    }

    /**
     * @description Opens or closes the modal to add items to collections.
     * @param {isOpen} boolean to indicate if modal will be opened or not.
     */
    function toogleModalCollectionVisibility (isOpen) {
      return new Promise((resolve) => {
        if (isOpen) {
          selectedIds.value = Object.keys(store.state.cardsSelectedIds).filter((id) => store.state.cardsSelectedIds[id])
          store.dispatch({
            type: 'getCollections',
            ids: selectedIds.value
          }).then(() => {
            isCollectionModalOpen.value = isOpen
            resolve()
          })
        } else {
          isCollectionModalOpen.value = isOpen
          resolve()
        }
      })
    }

    /**
     * @description Go Back to collection Modal and reload collections
     */
    function backToCollectionModal () {
      const promise = toogleModalCollectionVisibility(true)
      promise.then(() => toggleCreateCollectionsModal(false))
    }

    /**
     * @description Go to Create collectionModal
     */
    function forwardToCreateCollectionModal () {
      toogleModalCollectionVisibility(false)
      toggleCreateCollectionsModal(true)
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Updates the selection of the checkbox based on the number of selected cards.
     * @param {counterSelected} number of selected cards.
     */
    function updateSelectionBasedOnCounter (counterSelected) {
      const allAreCardsSelected = counterSelected && counterSelected === Object.keys(store.state.cardsSelectedIds).length
      store.commit({
        type: 'IS_SELECT_ALL_ACTIVE',
        areAllCardsSelected: allAreCardsSelected
      })
      modelIsSelected.value = Boolean(allAreCardsSelected)
    }

    /**
     * @description Updates the collection data to check if it contains item id
     * @param collectionIndex index of the collection to update
     */
    function updateCollectionContainId (collectionIndex) {
      store.commit({
        type: 'ADD_REMOVE_ITEM_FROM_COLLECTION',
        positionCollection: collectionIndex
      })
    }

    return {
      store,
      isCollectionModalOpen,
      selectedIds,
      selectAllCheck,
      areManyCardsSelected,
      isIndeterminate,
      toggleAllCardsSelection,
      toogleModalCollectionVisibility,
      modelIsSelected,
      backToCollectionModal,
      forwardToCreateCollectionModal,
      createCollectionModalVisible,
      toggleCreateCollectionsModal,
      updateCollectionContainId
    }
  }
}
</script>
