<template>
  <section class="containerMisspellText" v-if="store.state.spellSuggestion.newSpell && store.state.showData && resultsExists">
    <section v-if="store.state.exactSearch" class="containerMisspellText__suggestedText">
      <span class="containerMisspellText__suggestedText--label">Did you mean&nbsp;</span>
      <span @click="makeNewSearch(store.state.spellSuggestion.newSpell, false)" class="containerMisspellText__suggestedText--link">
        {{store.state.spellSuggestion.newSpell}}
      </span>?
    </section>
    <section v-else class="containerMisspellText__alternativeText">
      <section class="containerMisspellText__alternativeText--searchMade">
        <span class="label" v-if="!store.state.exactSearch">Showing results for </span>
        <span @click="makeNewSearch(store.state.spellSuggestion.newSpell, false)" class="link">
          {{store.state.spellSuggestion.newSpell}}
        </span>
      </section>
      <section v-if="store.state.spellSuggestion.originalResults" class="containerMisspellText__alternativeText--suggestedSearch">
        <span class="label">Search instead for </span>
        <span @click="makeNewSearch(store.state.searchValue, true, store.state.spellSuggestion)" class="link">
          {{store.state.searchValue}}
        </span>
      </section>
      <section v-else class="containerMisspellText__alternativeText--suggestedSearch">
        <span class="label">No results for <b>{{store.state.searchValue}}</b></span>
      </section>
    </section>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'misspellLink',
  emits: ['updateSearchInput'],
  setup (props, { emit }) {
    const store = useStore()

    /**
     * @description check if there are results of both terms, original and corrected
     */
    const resultsExists = computed(() => {
      return !(store.state.spellSuggestion.newSpellNumber === 0 && !store.state.spellSuggestion.originalResults)
    })

    /**
     * @description Launches a new search and update search term in url.
     * @param searchTerm new search term.
     */
    function makeNewSearch (searchTerm, exactSearchParam, spellSuggestionParam) {
      emit('updateSearchInput', searchTerm)

      store.commit({
        type: 'SET_EXACT_SEARCH',
        exactSearch: exactSearchParam
      })

      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTerm,
        apply: true
      })

      store.commit({
        type: 'CLEAN_PAGINATION'
      })

      // this.$Progress.start()

      store.dispatch({
        type: 'getResultsSearch'
      }).then(
        (response) => {
          /* setTimeout(() => {
            this.$Progress.finish()
          }, 500) */

          if (exactSearchParam) {
            store.commit({
              type: 'SET_SPELL_SUGGESTION',
              newSpell: spellSuggestionParam.newSpell,
              originalResults: spellSuggestionParam.originalResults,
              newSpellNumber: spellSuggestionParam.newSpellNumber
            })
          }
        },
        (error) => {
          console.error(error)
        }
      )
    }

    return {
      store,
      makeNewSearch,
      resultsExists
    }
  }
}
</script>
