<template>
  <section class="containerFilterPill">
    <span class="containerFilterPill__label">{{label}}</span>
    <span class="grey-icon-clear" @click="clearPill()"></span>
  </section>
</template>

<script>
export default {
  name: 'filterPill',
  props: ['label'],
  emits: ['clearPill'],
  setup (props, { emit }) {
    /**
     * @description Propagates event to notify the pill has been deleted.
     */
    function clearPill () {
      emit('clearPill')
    }

    return {
      clearPill
    }
  }
}
</script>
