<template>
  <div class="containerPreview">
    <article class="containerPreview__dataFields">
      <section class="containerPreview__dataFields--head">
        <div class="thumbnail" :class="{'persona': source === 'persona'}">
          <cardImages  :class="{'previewPersona': source === 'persona'}" :item="previewItem" :indexItem="store.state.pagination.size"></cardImages>
        </div>
        <div v-if="source === 'persona'" class="contentRight">
          <p class="contentRight__personaTitle">{{previewItem.persona_title}}</p>
          <p class="contentRight__agency persona">{{previewItem.agency[0]}}</p>
          <p class="contentRight__country">{{previewItem.country[0]}}</p>
          <a class="contentRight__detail" :class="detailAdditionalClass" :href="contentUrl" @click="goToDetail">READ MORE</a>
        </div>
        <div v-else class="contentRight">
          <reactionsBox :itemId="previewItem.id" class="contentRight__reactions"/>
          <p class="contentRight__date">{{store.getters.formatDate(previewItem.date_created)}}</p>
          <p class="contentRight__agency">{{previewItem.agency[0]}}</p>
          <a class="contentRight__detail" :class="{'news': source === 'news'}" :href="contentUrl" @click="goToDetail">READ MORE</a>
        </div>
      </section>

      <section class="containerPreview__dataFields--generalInfo" v-if="source==='intelligence'">
        <p class="labelPreview box-vertical">
          <span class="labelPreview__title">Summary:</span> {{valueString(previewItem.description)}}
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Source / Author:</span>
          <span>{{authorLabel}}</span>
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Content:</span> {{valueString(previewItem.content_type)}}
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Client:</span> {{valueString(previewItem.client[0])}}
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Brand:</span> {{valueString(previewItem.brand[0])}}
        </p>
        <p class="labelPreview labelPreview-twolines box-vertical">
          <span class="labelPreview__title">{{agencyLabel(previewItem.agency)}} / Office:</span>
          <span class="labelPreview__label--agency">{{valueArray(previewItem.agency)}}</span>
        </p>
        <p class="labelPreview labelPreview-twolines box-vertical">
          <span class="labelPreview__title">Industry / Category:</span>
          <span>{{valueArray(previewItem.categories)}}</span>
        </p>
      </section>

      <section class="containerPreview__dataFields--generalInfo" v-else-if="source==='work'">
        <p class="labelPreview box-vertical">
          <span class="labelPreview__title">Summary:</span> {{valueString(previewItem.description)}}
        </p>
        <p class="labelPreview labelPreview-twolines box-vertical">
          <span class="labelPreview__title">{{agencyLabel(previewItem.agency)}} / Office:</span>
          <span class="labelPreview__label--agency">{{valueArray(previewItem.agency)}}</span>
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Client:</span> {{valueString(previewItem.client[0])}}
        </p>
        <p class="labelPreview labelPreview-twolines box-vertical">
          <span class="labelPreview__title">Industry:</span>
          <span>{{valueArray(previewItem.industry)}}</span>
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Brand:</span> {{valueString(previewItem.brand[0])}}
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">Content:</span> {{valueString(previewItem.content_type)}}
        </p>
      </section>

      <section class="containerPreview__dataFields--generalInfo" v-else-if="source==='news'">
        <p class="labelPreview box-vertical">
          <contentHTML :content="contentSummary" />
        </p>
      </section>

      <section class="containerPreview__dataFields--generalInfo" v-else>
        <p class="labelPreview box-vertical">
          <span class="labelPreview__title">EXPERIENCE AT GREY:</span> {{valueString(previewItem.description)}}
        </p>
        <p class="labelPreview  box-vertical">
          <span class="labelPreview__title">INDUSTRY EXPERIENCE:</span>
          <span>{{valueArray(previewItem.categories)}}</span>
        </p>
        <p class="labelPreview">
          <span class="labelPreview__title">MANAGER:</span>
          <a class="labelPreview__link" :href="managerProfileLink" @click.stop>{{valueString(previewItem.employee_manager)}}</a>
        </p>
      </section>
      <similar-assets v-if="showSimilarAssets" :articleId="previewItem.id" stylesFrom="preview" :path="relatedSource"></similar-assets>
    </article>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import cardImages from '@/components/cardImages/cardImages'
import reactionsBox from '@/components/reactionsBox/reactionsBox'
import similarAssets from '@/components/similarAssets/similarAssets'
import contentHTML from '@/components/contentHTML/contentHTML'

export default {
  name: 'generalPreview',
  components: {
    cardImages,
    reactionsBox,
    similarAssets,
    contentHTML
  },
  props: {
    previewItem: null,
    source: {
      type: String
    },
    contentUrl: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const store = useStore()

    /**
     * @description Validate if show similar assets
     */
    const showSimilarAssets = computed(() => {
      return props.source === 'work' || props.source === 'intelligence'
    })

    /**
     * @description Set label for item author
     */
    const authorLabel = computed(() => {
      return props.previewItem.author.length ? props.previewItem.author.map(author => author.name).join(', ') : 'Not specified'
    })

    /**
     * @description Set a String value from a string
     * @param valueParam initial string
     */
    const valueString = computed(() => {
      return valueParam => valueParam || 'Not specified'
    })

    /**
     * @description Set a String value from an array
     * @param valueParam initial array
     */
    const valueArray = computed(() => {
      return valueParam => valueParam.length ? valueParam.join(', ') : 'Not specified'
    })

    /**
     * @description Set a String value from an array
     * @param valueParam initial array
     */
    const detailAdditionalClass = computed(() => {
      return props.source === 'news' || props.source === 'persona' ? props.source : ''
    })

    /**
     * @description content for summary field
     */
    const contentSummary = computed(() => {
      return `<span class="labelPreview__title">Summary:</span>${props.previewItem.description ? props.previewItem.description : ''}`
    })

    /**
     * @description returns the correct url used to related details
     */
    const relatedSource = computed(() => {
      return props.source === 'work' ? 'detailWork/' : 'detail/'
    })

    /**
     * @description Returns the label for Agencies
     * @param {Array} agencies list of agencies
     */
    const agencyLabel = computed(() => agencies => {
      return agencies.length > 1 ? 'Agencies' : 'Agency'
    })

    /**
     * @description Gets link of manager profile
     */
    const managerProfileLink = computed(() => {
      return `${process.env.VUE_APP_PF_URL}profile/${props.previewItem.employee_manager_employeecode}`
    })

    onMounted(() => {
      setUpSideBar()
    })

    /**
     * @description Setups the initial configuration of the component.
     */
    function setUpSideBar () {
      store.dispatch({
        type: 'updateViews',
        id: props.previewItem.id
      })
    }

    /**
     * @description Reditect to detail page
     */
    function goToDetail () {
      window.location = props.contentUrl
    }

    return {
      store,
      goToDetail,
      showSimilarAssets,
      authorLabel,
      valueString,
      valueArray,
      detailAdditionalClass,
      contentSummary,
      relatedSource,
      agencyLabel,
      managerProfileLink
    }
  }
}
</script>
