<template>
  <section ref="refDescription" />
</template>

<script>
import { onMounted, ref } from 'vue'
export default {
  name: 'contentHTML',
  props: ['content'],
  setup (props) {
    const tempContent = ref(props.content)
    const refDescription = ref(null)

    onMounted(() => {
      getCleanContent()
    })

    /**
     * @description take the current content and return a clean html string.
     */
    function getCleanContent () {
      const allowedTags = ['u', 'i', 'em', 'br', 'b']

      allowedTags.map(tag => {
        if (tag === 'br') {
          tempContent.value = tempContent.value.replace(/<br>/g, '_left_br/_right_')
            .replace(/<br \/>/g, '_left_br/_right_')
            .replace(/<br\/>/g, '_left_br/_right_')
        } else {
          const regexExpressionOpen = `<${tag}>`
          const regexExpressionClose = `</${tag}>`

          const reOpen = new RegExp(regexExpressionOpen, 'g')
          tempContent.value = tempContent.value.replace(reOpen, `_left_${tag}_right_`)

          const reClose = new RegExp(regexExpressionClose, 'g')
          tempContent.value = tempContent.value.replace(reClose, `_left_/${tag}_right_`)
        }
      })

      tempContent.value = tempContent.value.replace(/(<([^a>]+)>)/ig, '')
        .replace(/_left_/g, '<')
        .replace(/_right_/g, '>')
        .replace(/<\/em>/g, '</em>&nbsp;')
        .replace(/<\/i>/g, '</i>&nbsp;')
        .replace(/<a /g, '<a target="_blank"')
      refDescription.value.innerHTML = tempContent.value
    }

    return {
      tempContent,
      refDescription
    }
  }
}
</script>
